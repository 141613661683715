/**
 * HDM Reward Engine API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 *  Customer Segment    SObject Name: HDM_CustomerSegment__c  Custom Object: True
 */
export interface CustomerSegment { 
    /**
     * Record ID    Name: Id  SF Type: id  Nillable: False
     */
    id?: string | null;
    /**
     * Customer Segment Name    Name: Name  SF Type: string  Nillable: True
     */
    name?: string | null;
    /**
     * Account Group    Name: HDM_CommerceAccountGroup__c  SF Type: reference  Custom field  Nillable: True
     */
    hDM_CommerceAccountGroup__c?: string | null;
}

